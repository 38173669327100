<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="Sonic 🚀">
      <b-card-text>Statistique de livraison</b-card-text>
    </b-card>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-container fluid>
      <h2>
        Performance et Taux de Réussite des Fournisseurs
      </h2>
      <p>Analyse mensuelle des performances des fournisseurs. Les données incluent le nombre total de commandes passées, ainsi que les taux de retour et de réussite pour chaque fournisseur. Les fournisseurs sont classés par nombre de commandes, offrant ainsi une vue détaillée de leur performance dans la livraison des commandes.</p>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th>Fournisseur</th>
              <th>Nombre de Commandes</th>
              <th>Taux de Retour</th>
              <th>Taux de Réussite</th>
            </tr>
          </thead>
          <tbody v-if="supplierPerformance.length">
            <tr
              v-for="supplierData in supplierPerformance"
              :key="supplierData.supplier.id"
            >
              <td>{{ supplierData.supplier.username }}</td>
              <td>{{ supplierData.order_count }}</td>
              <td>{{ formatPercentage(supplierData.return_rate) }}</td>
              <td>{{ formatPercentage(supplierData.success_rate) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                Aucune donnée disponible
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  BCard, BCardText,
  BSpinner,
} from 'bootstrap-vue'

import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BCardText,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      user: {},
      isFreeTrial: true,
      supplierPerformance: [],
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }
  },
  created() {
    this.user = storeAuth.state.user
    if (this.user.role === 'admin') {
      this.fetchSupplierPerformance()
    }
  },
  methods: {
    async fetchSupplierPerformance() {
      try {
        this.isLoading = true
        const url = `/api/kpi/supplier-performance/?year=${this.year}&month=${this.month}`
        const { data } = await axios.get(url)
        this.supplierPerformance = data
        this.isLoading = false
      } catch (error) {
        console.error('Error fetching supplier performance:', error)
      }
    },
    formatPercentage(value) {
      return `${(value * 100).toFixed(2)}%`
    },
    show(level) {
      this.$router.push({ name: 'order-list', params: { level } })
    },
  },
}
</script>
  <style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-good-table.scss';

  .b-card {
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    margin-bottom: 1rem;
    background-color: #fff;
    padding: 1rem;
  }

  .b-badge {
    font-size: 14px;
  }
  </style>
